//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/theme/variables";

// Colors
$mdc-drawer-title-ink-color: mdc-theme-prop-value(on-surface) !default;
$mdc-drawer-subtitle-ink-color: mdc-theme-prop-value(on-surface) !default;
$mdc-drawer-item-inactive-ink-color: mdc-theme-prop-value(on-surface) !default;
$mdc-drawer-item-activated-ink-color: mdc-theme-prop-value(primary) !default;
$mdc-drawer-divider-color: mdc-theme-prop-value(on-surface) !default;
$mdc-drawer-surface-fill-color: mdc-theme-prop-value(surface) !default;

// Opacity
$mdc-drawer-title-ink-opacity: mdc-theme-text-emphasis(high) !default;
$mdc-drawer-subtitle-ink-opacity: mdc-theme-text-emphasis(medium) !default;
$mdc-drawer-item-inactive-icon-ink-opacity: mdc-theme-text-emphasis(medium) !default;
$mdc-drawer-item-inactive-text-ink-opacity: mdc-theme-text-emphasis(high) !default;
$mdc-drawer-item-active-icon-ink-opacity: 1 !default;
$mdc-drawer-item-active-text-ink-opacity: mdc-theme-text-emphasis(high) !default;
$mdc-drawer-divider-opacity: .12 !default;

// Widths
$mdc-drawer-width: 256px !default;
$mdc-drawer-list-item-spacing: 4px !default;
$mdc-drawer-surface-padding: 16px !default;

// Animations
$mdc-drawer-animation-enter: 250ms !default;
$mdc-drawer-animation-exit: 200ms !default;

// Scrim
$mdc-drawer-modal-scrim-color: mdc-theme-prop-value(on-surface) !default;
$mdc-drawer-modal-scrim-opacity: .32 !default;

$mdc-drawer-z-index: 6 !default;
$mdc-drawer-modal-elevation: 16 !default;
